*{
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: 0;
  padding: 0;
  a, a:hover{
    text-decoration: none;
  }  
  li{
    list-style-type: none;
  }
  audio, img, iframe, video{
    display: inline-block;
    height: auto;
    width: 100%;
  }
}